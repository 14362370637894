/**
 * 工具类操作
 */
import { mapGetters } from 'vuex'
import {
  DEVICE_MOBILE,
  BODY_MAX_WIDTH
} from '@/constants'
export default {
  computed: {
    ...mapGetters(['header_height', 'device']),
    marginTop() {
      return {
        marginTop: `${this.header_height} !important`
      }
    },
    is_mobile() {
      return this.device === DEVICE_MOBILE
    },
    maxWidth() {
      return {
        width: this.is_mobile ? '100%' : BODY_MAX_WIDTH
      }
    },
    detailWidth() {
      if(this.is_mobile) {
        return {
          width: '100%',
          maxWidth: '300px',
          minWidth: '300px',
          minHeight: '204px',
          'pointer-events': 'painted'
        }
      }
      return {
        width: '400px',
        minHeight: '204px'
      }
    }
  },
  methods: {
    // 显示alert警告框
    showErrorAlert (message, type = 'error') {
      this.showBaseAlert(message, type)
    },
    // 显示alert警告框
    showSuccessAlert (message, type = 'success') {
      this.showBaseAlert(message, type)
    },
    // 显示alert警告框
    showInfoAlert (message, type = 'info') {
      this.showBaseAlert(message, type)
    },
    // 显示alert警告框
    showWarningAlert (message, type = 'warning') {
      this.showBaseAlert(message, type)
    },
    showBaseAlert(message, type = 'info') {
      this.$store.dispatch('app/setAlertMessage', message)
      this.$store.dispatch('app/setAlertType', type)
      this.$store.dispatch('app/setAlertShow', true)
    },
    hideAllAlert() {
      this.$store.dispatch('app/setAlertShow', false)
    }
  }
}
