<template>
  <v-container
    fluid
    class="ma-0 pa-0 pb-16 cs-partner-bg"
  >
    <div class="mx-auto my-0 py-0 px-3 px-sm-0" :style="maxWidth">
      <title-block title="合作伙伴" />
      <sub-title-block title="主办方" />
      <partner-block :size="260" :items="owners" />
      <sub-title-block title="指导单位" />
      <partner-block :size="260" :items="directs" />
      <sub-title-block title="承办单位" />
      <partner-block :size="260" :items="undertakes" />
      <sub-title-block title="战略合作伙伴" />
      <partner-block :size="260" :items="fires" />
      <sub-title-block title="特邀合作伙伴" />
      <partner-block :size="260" :items="invite_partners" />
      <sub-title-block title="战略合作媒体" />
      <partner-block :size="260" :items="policys" />
      <sub-title-block title="合作社区（持续更新中）" />
      <partner-block :items="partners" />
      <sub-title-block v-if="false" title="直播平台" />
      <partner-block v-if="false" :items="lives" />
    </div>
  </v-container>
</template>
<script>
import { BRANDCENTER } from '@/datacenter.js'
import Tools from '@/mixins/Tools.js'
import TitleBlock from '@/views/components/TitleBlock.vue'
import SubTitleBlock from '@/views/components/SubTitleBlock.vue'
import PartnerBlock from '@/views/components/PartnerBlock.vue'
export default {
  name: 'IndexPartner',
  mixins: [Tools],
  components: {
    TitleBlock,
    SubTitleBlock,
    PartnerBlock
  },
  computed: {
    // 主办方
    owners() {
      return BRANDCENTER.filter((item) => {
        return item.branch === 'owner'
      })
    },
    // 指导单位
    directs() {
      return BRANDCENTER.filter((item) => {
        return item.branch === 'direct'
      })
    },
    // 承办单位
    undertakes() {
      return BRANDCENTER.filter((item) => {
        return item.branch === 'undertake'
      })
    },
    // 战略合作媒体
    policys() {
      return BRANDCENTER.filter((item) => {
        return item.branch === 'policy'
      })
    },
    // 战略合作伙伴
    fires() {
      return BRANDCENTER.filter((item) => {
        return item.branch === 'fire'
      })
    },
    // 特邀合作伙伴
    invite_partners() {
      return BRANDCENTER.filter((item) => {
        return item.branch === 'invite_partner'
      })
    },
    // 合作社区
    partners() {
      return BRANDCENTER.filter((item) => {
        return item.branch === 'partner'
      })
    },
    // 直播平台
    lives() {
      return BRANDCENTER.filter((item) => {
        return item.branch === 'live'
      })
    }
  }
}
</script>
<style lang="sass" scoped>
.cs-partner-bg
  background-color: #F8F9FC
</style>