<template>
  <v-row no-gutters>
    <template v-if="size === 260 && !is_mobile">
    <v-col
      v-for="(item, index) of items"
      :key="`partner-${item.branch}-${index}`"
      class="col-4 col-sm-3 align-self-stretch pa-1 pa-sm-3"
    >
      <v-hover v-slot="{ hover }">
        <v-img
          :width="size"
          :max-width="size"
          :src="`${item.picture}?3`"
          :class="['card-base', {'card-hover': hover}]"
        />
      </v-hover>
    </v-col>
    </template>
    <template v-else>
    <v-col
      v-for="(item, index) of items"
      :key="`partner-${item.branch}-${index}`"
      class="align-self-stretch pa-1 pa-sm-3"
      :class="[size === 260 ? 'col-6 col-sm-3':'col-4 col-sm-2']"
    >
      <v-hover v-slot="{ hover }">
        <v-img
          width="100%"
          :src="`${item.picture}?3`"
          :class="['card-base', {'card-hover': hover}]"
        />
      </v-hover>
    </v-col>
    </template>
  </v-row>
</template>
<script>
import Tools from '@/mixins/Tools.js'
export default {
  name: 'PartnerBlock',
  mixins: [Tools],
  props: {
    items: {
      type: Array,
      default: () => []
    },
    size: {
      type: Number,
      default: 215
    }
  }
}
</script>
<style lang="sass" scoped>
.card-base
  transition: all .2s
.card-hover
  transform: translateY(-5px)
  box-shadow: 0 26px 40px -24px rgba(147, 157, 178, 0.3)
</style>