<template>
  <div class="font-weight-bold text-h6 text-sm-h5 pt-8 pb-4 pt-sm-12">{{ title }}</div>
</template>
<script>
export default {
  name: 'SubTitleBlock',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
